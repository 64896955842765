import React from "react";
import NodeGalleryTeaser from "components/Gallery/NodeGalleryTeaser";
import classNames from "lib/classNames";

export default function NodeGalleryListPreview({ nodes, wide = false }) {
  return (
    <div
      className={classNames(
        "grid grid-cols-1 gap-8",
        wide ? "lg:grid-cols-3" : "lg:grid-cols-2"
      )}
    >
      {nodes.galleries.map((node) => {
        return (
          <div key={node.id}>
            <NodeGalleryTeaser node={node} />
          </div>
        );
      })}
    </div>
  );
}

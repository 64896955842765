import React from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { ChevronRightIcon } from "@heroicons/react/outline";
import NodePublicationTeaser from "components/Publication/NodePublicationTeaser";
import classNames from "lib/classNames";
import NodeGalleryListPreview from "components/Gallery/NodeGalleryListPreview";

export default function HomepagePublication({ nodes, hasPublications }) {
  const { t } = useTranslation("home");

  return (
    <section id="publications-galleries" className="relative overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="flex flex-col xl:flex-row">
          {nodes.publications?.length > 0 && (
            <div className="relative w-full xl:w-5/12 py-24 xl:pr-10">
              <div
                className="absolute bottom-0 right-1/2 text-right text-secondary text-[210px] font-serif opacity-5"
                aria-hidden="true"
              >
                {t("publications.decoration")}
              </div>
              <div className="mb-20 flex flex-col md:flex-row md:items-center gap-4">
                <h2 className="text-3xl font-serif">
                  {t("publications.title")}
                </h2>

                <div>
                  <ChevronRightIcon className="inline-block h-4 w-4 mr-2" />
                  <Link
                    href={t("common:publication.path")}
                    prefetch={false}
                    passHref
                  >
                    <a className="underline decoration-primary underline-offset-4 hover:text-primary transition-colors">
                      {t("publications.more")}
                    </a>
                  </Link>
                </div>
              </div>

              <NodePublicationTeaser node={nodes.publications[0]} />
            </div>
          )}
          {nodes.galleries?.length > 0 && (
            <div
              className={classNames(
                "w-full relative py-24 text-white bg-secondary-dark",
                hasPublications && "xl:w-7/12 xl:pl-20"
              )}
            >
              <div
                className={classNames(
                  "absolute left-1/2 inset-y-0 w-screen bg-secondary-dark -translate-x-1/2",
                  hasPublications && "xl:left-0 xl:translate-x-0"
                )}
                aria-hidden="true"
              />

              <div className="relative">
                <div className="mb-20 flex flex-col md:flex-row md:items-center gap-4">
                  <h2 className="text-3xl font-serif">
                    {t("galleries.title")}
                  </h2>

                  <div>
                    <ChevronRightIcon className="inline-block h-4 w-4 text-primary mr-2" />
                    <Link
                      href={t("common:gallery.path")}
                      prefetch={false}
                      passHref
                    >
                      <a className="underline decoration-primary underline-offset-4 hover:text-primary transition-colors">
                        {t("galleries.more")}
                      </a>
                    </Link>
                  </div>
                </div>

                <NodeGalleryListPreview nodes={nodes} wide={!hasPublications} />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

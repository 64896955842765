import React from "react";
import Image from "next/image";
import formatFileSize from "lib/formatFileSize";
import { splitDate } from "lib/formatDate";
import { DownloadIcon, EyeIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";

export default function NodePublicationTeaser({ node }) {
  const { lang, t } = useTranslation("common");
  const extension = node.field_file.filename.split(".").pop();
  const filesize = formatFileSize(node.field_file.filesize);
  const splitedDate = splitDate(node.created, lang);

  return (
    <article className="relative p-8 md:p-2 flex flex-col md:flex-row">
      <div
        className="absolute left-0 md:left-16 right-0 top-16 md:top-0 bottom-0 border border-secondary border-opacity-50 bg-white"
        aria-hidden="true"
      />
      <div className="flex-initial w-5/12 md:w-3/12 xl:w-4/12">
        <a
          href={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${node.field_file.uri.url}`}
          className="group"
          title={`Ouvrir "${node.field_file.filename}" dans un nouvel onglet`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="aspect-w-21 aspect-h-29.7">
            <Image
              src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${node.field_image.uri.url}`}
              width={210}
              height={297}
              layout="fill"
              objectFit="cover"
              alt={node.field_image?.resourceIdObjMeta.alt}
            />
            <span
              className="absolute left-0 top-auto bottom-0 border-t-[2rem] border-t-primary border-l-[2rem] border-l-transparent w-8 h-8 bg-white group-hover:scale-110 group-hover:border-t-primary-light transition-all origin-bottom-left"
              aria-hidden="true"
            />
          </div>
        </a>
      </div>
      <div className="relative flex-1 flex flex-col lg:flex-row xl:flex-col lg:gap-8 xl:gap-0 pt-8 md:p-10">
        <div className="flex-1">
          <h3 className="text-xl font-serif mb-2">{node.title}</h3>

          <div className="text-xl font-serif capitalize mb-6">
            {splitedDate.month} {splitedDate.year}
          </div>

          <div className="text-secondary mb-6">
            <span className="uppercase">{extension}</span> - {filesize}
          </div>
        </div>

        <div className="flex-1 flex flex-col xl:flex-row -mx-2">
          <a
            href={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${node.field_file.uri.url}`}
            className="group flex items-center p-2"
            title={`Télécharger "${node.field_file.filename}" (${filesize})`}
            download
          >
            <span className="inline-block p-3 bg-primary text-primary-darker rounded-full mr-2 group-hover:bg-primary-dark transition-colors">
              <DownloadIcon className="h-4 w-4" />
            </span>
            <span className="underline underline-offset-4 decoration-transparent group-hover:decoration-primary transition-colors">
              {t("utils.files.download")}
            </span>
          </a>
          <a
            href={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${node.field_file.uri.url}`}
            className="group flex items-center p-2"
            title={`Ouvrir "${node.field_file.filename}" dans un nouvel onglet`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="inline-block p-3 bg-primary text-primary-darker rounded-full mr-2 group-hover:bg-primary-dark transition-colors">
              <EyeIcon className="h-4 w-4" />
            </span>
            <span className="underline underline-offset-4 decoration-transparent group-hover:decoration-primary transition-colors">
              {t("utils.files.see")}
            </span>
          </a>
        </div>
      </div>
    </article>
  );
}

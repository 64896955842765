import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { PhotographIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";

export default function NodeGalleryTeaser({ node }) {
  const router = useRouter();
  const firstImage = node.field_images[0];
  const { t } = useTranslation("common");

  return (
    <article
      className="group cursor-pointer"
      onClick={() => router.push(node.path.alias)}
    >
      <div className="aspect-w-16 aspect-h-10 mb-4 overflow-hidden">
        <Image
          src={firstImage.image_style_uri.small_16by10}
          width={firstImage.resourceIdObjMeta.width}
          height={firstImage.resourceIdObjMeta.height}
          layout="fill"
          objectFit="cover"
          alt={firstImage.resourceIdObjMeta.alt}
          className="group-hover:scale-110 transition-transform"
        />
      </div>
      <h3 className="text-xl font-serif mb-2">
        <Link href={node.path.alias} prefetch={false} passHref>
          <a className="underline underline-offset-4 decoration-transparent transition-colors group-hover:decoration-primary">
            {node.title}
          </a>
        </Link>
      </h3>
      <div>
        <PhotographIcon className="inline-block h-4 w-4 text-primary mr-4" />
        {node.field_images.length}{" "}
        {node.field_images.length > 1
          ? t("utils.image_count.other")
          : t("utils.image_count.one")}
      </div>
    </article>
  );
}
